.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.addComment {
  height: 50px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 20px 10px 10px;
  background-color: rgba(220, 220, 220, 0.3);
  border-radius: 4px;
}
.addComment.replyComment {
  background-color: #fff;
  height: 34px;
  padding: 2px;
  border: 1px solid #DCDCDC;
}
.addComment.replyComment .commentInputBox-input {
  border: none;
}
.addComment .addCommentForm {
  width: 100%;
  height: 100%;
  position: relative;
}
.addComment .userCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
}
.addComment .commentInputBox {
  height: 30px;
  position: absolute;
  left: 30px;
  right: 0;
}
.addComment .commentInputBox-input {
  border: 1px solid #DCDCDC;
  height: 30px;
  width: 100%;
  line-height: 30px;
  font-size: 14px;
  padding: 0 10px;
  background: #FFFFFF;
  border-radius: 4px;
}
.addComment .commentInputBox .atUserInfo {
  position: absolute;
  color: #F55B23;
  top: 0;
  left: 0;
  line-height: 30px;
  font-size: 14px;
  padding: 0 8px 0 0;
}
