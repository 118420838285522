.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.trackinfo {
  position: relative;
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dcdcdc;
  padding-right: 20px;
}
.trackinfo-cover {
  width: 160px;
  height: 160px;
  margin: 0 20px 20px 0;
  overflow: hidden;
  border-radius: 4px;
}
.trackinfo-titleBox {
  position: absolute;
  height: 152px;
  left: 180px;
  top: 8px;
}
.trackinfo-title {
  width: 400px;
  line-height: 24px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.trackinfo-title .box-ellipsis-lineClamp {
  width: auto;
}
.trackinfo-title .explicit {
  flex-shrink: 0;
  margin-right: 6px;
  margin-top: -2px;
  width: 20px;
  height: 20px;
}
.trackinfo-ctrls,
.trackinfo-con-des {
  margin-top: 15px;
}
.trackinfo-ctrls .player_play_btn {
  width: 40px;
  height: 40px;
}
.trackinfo-con-des {
  font-size: 14px;
  color: #777;
  font-weight: 400;
  line-height: 18px;
  height: 18px;
  overflow: hidden;
}
.trackinfo-con-des .item {
  margin-right: 1.2em;
}
.trackinfo-con-des .item .heart {
  height: 16px;
  margin-right: 4px;
}
.trackinfo-des-title-wrapper {
  display: flex;
  gap: 24px;
}
.trackinfo-des-title {
  font-size: 16px;
  color: black;
  font-weight: 600;
  line-height: 22px;
  height: 22px;
  margin-bottom: 13px;
  cursor: pointer;
}
.trackinfo-des-title.active {
  color: #f55b23;
}
.trackinfo-des {
  font-size: 14px;
  color: #777;
  font-weight: 400;
  text-align: justify;
  padding-right: 20px;
  transition: 0.3s;
}
.trackinfo-des * {
  font-size: 14px;
}
.trackinfo-des a {
  color: #f55b23;
  text-decoration: underline;
}
.trackinfo .seeMorebtn {
  cursor: pointer;
  width: 32px;
  margin: 12px auto 0;
}
.trackinfo .seeMorebtn .img {
  width: 100%;
}
.trackinfo .seeMorebtn.notShowBtn {
  display: none;
}
.trackinfo .seeMorebtn.seeMorebtn_up {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  /* Firefox 4 */
  -webkit-transform: rotate(180deg);
  /* Safari and Chrome */
  -o-transform: rotate(180deg);
  /* Opera */
}
