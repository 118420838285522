.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.sharePageBtn {
  cursor: pointer;
  min-width: 100px;
  height: 40px;
  border: 2px solid #F55B23;
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  color: #F55B23;
  background-color: rgba(0, 0, 0, 0);
  border-color: #F55B23;
  padding: 0 10px 0 36px;
  background: url(../images/toShare.png) no-repeat;
  background-size: 18px 15px;
  background-position: 9px 10px;
}
.sharePageBtn:hover,
.sharePageBtn:focus,
.sharePageBtn .focus {
  color: #F55B23;
}
.shareModal {
  position: absolute;
  top: 100px;
  left: 50%;
  width: 600px;
  margin-left: -300px;
}
.shareModal .modal-body {
  padding: 14px;
}
.shareModal .shareBox {
  width: 100%;
}
.shareModal .shareBox .shareInfo {
  position: relative;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}
.shareModal .shareBox .shareInfo-cover {
  width: 100px;
  height: 100px;
  margin: 0 15px 0 0;
}
.shareModal .shareBox .shareInfo-cover .img {
  width: 100%;
  height: 100%;
}
.shareModal .shareBox .shareInfo-main {
  width: 450px;
  max-height: 150px;
  overflow: hidden;
}
.shareModal .shareBox .shareInfo-title {
  margin-top: 12px;
  font-size: 16px;
}
.shareModal .shareBox .shareInfo-des {
  margin-top: 12px;
  overflow: hidden;
  font-size: 14px;
}
.shareModal .shareBox .shareInfo-des br {
  display: block;
  line-height: 2px;
  font-size: 2px;
  content: ' ';
}
.shareModal .shareBox-title {
  font-weight: bold;
  font-size: 16px;
  margin: 15px 0;
}
.shareModal .shareBox-content {
  margin-top: 12px;
}
.shareModal .shareBox-links-item {
  margin-right: 12px;
}
.shareModal .shareBox-links-img {
  background-color: #fff;
  vertical-align: top;
  margin: 0 3px 0 0;
  cursor: pointer;
  display: inline-block;
  width: 40px;
  height: 40px;
}
.shareModal .autoplay-btn {
  font-size: 14px;
  display: flex;
  margin-top: 10px;
}
.shareModal .autoplay-btn .switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}
.shareModal .autoplay-btn .switch input {
  display: none;
}
.shareModal .autoplay-btn .switch .slider {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 24px;
  transition: 0.4s;
}
.shareModal .autoplay-btn .switch .slider:before {
  position: absolute;
  border-radius: 50%;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
.shareModal .autoplay-btn .switch .slider:after {
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  content: 'off';
  height: 16px;
  right: 4px;
  left: auto;
  bottom: 4px;
  color: #fff;
  padding: 0 3px;
}
.shareModal .autoplay-btn .switch input:checked + .slider {
  background-color: #f55b23;
}
.shareModal .autoplay-btn .switch input:focus + .slider {
  box-shadow: 0 0 1px #f55b23;
}
.shareModal .autoplay-btn .switch input:checked + .slider:before {
  transform: translateX(24px);
}
.shareModal .autoplay-btn .switch input:checked + .slider:after {
  left: 4px;
  right: auto;
  content: 'on';
}
