.trackPlay{
  &-comment{
    padding-right: 20px;
    .commentList-title{
      border: none;
    }
  }
  .trackListCon{
    width: 300px;
    overflow-x: hidden;
    // height: 900px;
    // overflow: hidden;
    .trackListCon_title{
      margin-bottom: 30px;
      font-weight:  bold;
      font-size: 20px;
    }
    .trackListCon_list{
      max-height: 600px;
      margin-right: -20px;
      overflow-x:hidden;
      overflow-y: scroll;
    }
  }
}
