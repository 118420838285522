.commentList {
  padding: 20px 0;
  &-title {
    padding-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 10px;
    font-size: 20px;
    color: black;
    font-weight: 600;
  }
}
