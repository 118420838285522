.summary-container {
  position: absolute;
  width: 300px;
  background: #fafafa;
  padding: 24px;
  border-radius: 16px;
  font-size: 16px;
  top: 380px;
  &.sticky {
    position: fixed;
    top: 51px;
  }
  h1 {
    font-size: 18px;
    color: #000;
    margin-bottom: 1em;
    font-weight: 600;
  }
  ul {
    list-style: inside;
    li {
      margin-bottom: 1em;
      cursor: pointer;
      &.active {
        color: #f55b23;
      }
    }
  }
}

.summary-content {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  // text-align: justify;
  .title {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    line-height: 22px;
    height: 22px;
    margin-bottom: 13px;
    cursor: pointer;
    line-height: 1.2em;
  }
  .summary-text {
    font-size: 14px;
    margin-bottom: 1.5em;
  }
  .outline-time {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-bottom: .5em;
    gap: 4px;
    &:hover {
      color: #f55b23;
    }
  }

  .outline-title {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    margin-bottom: .5em;
    line-height: 1.5em;
  }

  .time {
    font-size: 16px;
    font-weight: 400;
  }

  strong {
    line-height: 1.2em;
    color: #000;
  }
  .outline-time-icon {
    height: 16px;
  }
}
