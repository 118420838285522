.font20bb {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.font14br {
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.font12cr {
  font-size: 12px;
  color: #9f9f9f;
  font-weight: 400;
}
.font16cr {
  font-size: 16px;
  color: #777;
  font-weight: 400;
}
.font30bb {
  font-size: 30px;
  color: black;
  font-weight: 600;
}
.font14cr {
  font-size: 14px;
  color: #777;
  font-weight: 400;
}
.font16br {
  font-size: 16px;
  color: black;
  font-weight: 400;
}
.font16bb {
  font-size: 16px;
  color: black;
  font-weight: 600;
}
.font20cb {
  font-size: 20px;
  color: #F55B23;
  font-weight: 600;
}
.font20cr {
  font-size: 20px;
  color: #777;
  font-weight: 400;
}
.commentItem {
  padding-top: 10px;
  border-bottom: 1px solid #dcdcdc;
}
.commentItem:last-child {
  border: none;
}
.commentItem .commentItemBox {
  padding-bottom: 10px;
}
.commentItem .commentItemBox .commentItemCover {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
.commentItem .commentItemBox .commentItemCover-img {
  border-radius: 50%;
  /* border: #000 solid 1px; */
}
.commentItem .commentItemBox .commentItemTitle {
  font-size: 16px;
  line-height: 20px;
  height: 20px;
  margin-bottom: 4px;
}
.commentItem .commentItemBox .commentItemTitle .userName {
  padding-right: 0.5em;
  color: #F55B23;
}
.commentItem .commentItemBox .commentItemDes {
  margin-top: 10px;
  font-size: 14px;
  line-height: 140%;
  word-break: break-word;
  color: #333;
}
.commentItem .commentItemBox .commentItemDes .userName {
  padding-right: 0.5em;
  color: #F55B23;
}
.commentItem .commentItemBox .commentItemDes a {
  color: #f55b23;
  text-decoration: underline;
}
.commentItem .commentItemBox .commentItemModify {
  padding-right: 4px;
}
.commentItem .commentItemBox .commentItemModify .textInput {
  width: 100%;
  padding: 4px 8px;
  line-height: 140%;
  font-size: 14px;
}
.commentItem .commentItemBox .commentItemModify .commentItemUpdate {
  margin: 12px 0;
  color: #F55B23;
  padding: 4px 6px;
  border: #F55B23 2px solid;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
.commentItem .commentItemBox .commentItemCtrl {
  margin-top: 20px;
  margin-bottom: 10px;
}
.commentItem .commentItemBox .commentItemCtrl .commentItemReply {
  cursor: pointer;
  font-size: 12px;
  border-radius: 4px;
  color: #333;
  padding: 2px 8px;
  background-color: initial;
  font-weight: 700;
}
.commentItem .commentItemBox .commentItemCtrl .commentItemReply:hover,
.commentItem .commentItemBox .commentItemCtrl .commentItemReply:active,
.commentItem .commentItemBox .commentItemCtrl .commentItemReply:focus {
  color: #F55B23;
}
.commentItem .childComment {
  padding-left: 24px;
}
.commentItem .childComment-more {
  text-align: center;
  transition: 0.3s;
  margin: 10px 0;
}
.commentItem .childComment-more .img {
  cursor: pointer;
  height: 34px;
}
